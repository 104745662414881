<template>
  <v-row
    :justify="hasPagination ? 'space-between' : 'center'"
    align="center"
    class="mt-5 flex-nowrap"
  >
    <confirm
      v-if="hasPagination"
      :extDialog="prevConfirm"
      text="Сохранить изменения?"
      @cancel="$emit('prev-edit'), (prevConfirm = false)"
      @confirm="$emit('prev-edit', true), (prevConfirm = false)"
    >
      <div
        class="arrow"
        @click.stop="isChanged ? (prevConfirm = true) : $emit('prev-edit')"
      >
        <v-icon>mdi-arrow-left</v-icon>
        Назад
      </div>
    </confirm>

    <slot></slot>

    <confirm
      v-if="hasPagination"
      :extDialog="nextConfirm"
      text="Сохранить изменения?"
      @cancel="$emit('next-edit'), (nextConfirm = false)"
      @confirm="$emit('next-edit', true), (nextConfirm = false)"
    >
      <div
        class="arrow"
        @click.stop="isChanged ? (nextConfirm = true) : $emit('next-edit')"
      >
        Вперед
        <v-icon>mdi-arrow-right</v-icon>
      </div>
    </confirm>
  </v-row>
</template>

<script>
import { ref } from "@vue/composition-api";

export default {
  props: {
    hasPagination: {
      type: Boolean,
      default: true,
    },
    isChanged: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const nextConfirm = ref(false);
    const prevConfirm = ref(false);

    return {
      nextConfirm,
      prevConfirm,
    };
  },
};
</script>

<style lang="scss" scoped>
.arrow {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  color: #afafaf;
  width: 70px;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  .v-icon {
    font-size: 18px;
    color: #afafaf !important;
    padding: 0 2px;
  }
  &:hover {
    color: $mainColor;
    .v-icon {
      color: $mainColor !important;
    }
  }
}
</style>
