<template>
  <FormPagination
    :has-pagination="isEdit && rows.length > 1"
    :is-changed="!!checkChanges(editedSession, initialSession)"
    @prev-edit="prevPage"
    @next-edit="nextPage"
  >
    <v-form class="form mx-auto" ref="form" id="assessmentSessionForm">
      <p v-if="!isEdit" class="subtitle primary--text text-center mb-4">
        Создание оценочной сессии
      </p>
      <p v-else class="subtitle primary--text text-center mb-4">
        Информация об оценочной сессии
      </p>

      <div class="mb-3 form-group">
        <p class="subtitle mb-1">Дата оценки:</p>
        <v-menu
          ref="menudate"
          v-model="dateMenu"
          attach="#assessmentSessionForm div.form-group:nth-child(1n)"
          :close-on-content-click="false"
          :return-value.sync="editedSession.date"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              v-model="editedSession.date"
              label="Выберите дату"
              readonly
              :rules="[val => !!val || '']"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="editedSession.date"
            :first-day-of-week="1"
            :min="new Date().toISOString().slice(0, 10)"
            @input="$refs.menudate.save(editedSession.date)"
          >
          </v-date-picker>
        </v-menu>
      </div>

      <div class="mb-3 form-group">
        <p class="subtitle mb-1">
          Время начала оценки <u>по московскому времени</u>:
        </p>
        <v-menu
          ref="menutime"
          v-model="timeMenu"
          attach="#assessmentSessionForm div.form-group:nth-child(2n)"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="editedSession.time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              v-model="editedSession.time"
              label="Выберите время"
              readonly
              :rules="[val => !!val || '']"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="timeMenu"
            format="24hr"
            v-model="editedSession.time"
            full-width
            :min="
              editedSession.date === new Date().toISOString().slice(0, 10)
                ? new Date().toLocaleTimeString()
                : null
            "
            @click:minute="$refs.menutime.save(editedSession.time)"
          >
          </v-time-picker>
        </v-menu>
      </div>

      <div class="mb-3 form-group" v-if="isEdit">
        <p class="subtitle mb-1">
          Время окончания оценки <u>по московскому времени</u>:
        </p>
        <v-menu
          ref="menutimeend"
          v-model="timeEndMenu"
          attach="#assessmentSessionForm div.form-group:nth-child(3n)"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="editedSession.assessmentSessionEndTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              v-model="editedSession.assessmentSessionEndTime"
              label="Выберите время"
              readonly
              :rules="[val => !!val || '']"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="timeEndMenu"
            format="24hr"
            v-model="editedSession.assessmentSessionEndTime"
            full-width
            :min="editedSession.time"
            @click:minute="
              $refs.menutimeend.save(editedSession.assessmentSessionEndTime)
            "
          >
          </v-time-picker>
        </v-menu>
      </div>

      <div class="mb-3 form-group">
        <p class="subtitle mb-1">
          Дата отправки логина и пароля участнику оценки:
        </p>
        <v-menu
          ref="notifymenudate"
          v-model="notifyDateMenu"
          attach="#assessmentSessionForm div.form-group:nth-child(4n)"
          :close-on-content-click="false"
          :return-value.sync="editedSession.notifyDate"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              v-model="editedSession.notifyDate"
              label="Выберите дату"
              readonly
              :rules="[val => !!val || '']"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="editedSession.notifyDate"
            :first-day-of-week="1"
            @input="$refs.notifymenudate.save(editedSession.notifyDate)"
          >
          </v-date-picker>
        </v-menu>
      </div>

      <div class="mb-3 form-group">
        <p class="subtitle mb-1">
          Время отправки логина и пароля участнику оценки
          <u>по московскому времени</u>:
        </p>
        <v-menu
          ref="notifymenutime"
          v-model="notifyTimeMenu"
          attach="#assessmentSessionForm div.form-group:nth-child(5n)"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="editedSession.notifyTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              v-model="editedSession.notifyTime"
              label="Выберите время"
              readonly
              :rules="[val => !!val || '']"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="notifyTimeMenu"
            format="24hr"
            v-model="editedSession.notifyTime"
            full-width
            @click:minute="$refs.notifymenutime.save(editedSession.notifyTime)"
          >
          </v-time-picker>
        </v-menu>
      </div>

      <div class="mb-3 form-group form-group_with-select">
        <p class="subtitle mb-1">Участник:</p>

        <v-select
          ref="participant"
          attach="#assessmentSessionForm div.form-group:nth-child(6n)"
          active-class="active-selection"
          hide-details
          dense
          outlined
          item-text="fullName"
          item-value="id"
          label="Выбрать участника"
          :items="participants.content"
          v-model="editedSession.participantUserId"
          :rules="[val => !!val || '']"
          validate-on-blur
          @click="selectInfinityScroll('participant')"
          :disabled="isEdit"
          :menu-props="{
            maxHeight: 175,
          }"
        ></v-select>
      </div>

      <div class="mb-3 form-group form-group_with-select">
        <p class="subtitle mb-1">Эксперты:</p>
        <v-select
          ref="expert"
          attach="#assessmentSessionForm div.form-group:nth-child(7n)"
          hide-details
          dense
          outlined
          v-model="editedSession.expertsList"
          item-text="fullName"
          item-value="id"
          return-object
          :items="experts.content"
          label="Выбрать экспертов"
          multiple
          chips
          :required="true"
          :rules="[val => !!val.length || '']"
          validate-on-blur
          class="chip-wrap"
          @click="selectInfinityScroll('expert')"
          :menu-props="{
            maxHeight: 190,
          }"
        >
          <template v-slot:selection="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.fullName }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </div>

      <div class="mb-3 form-group form-group_with-select">
        <p class="subtitle mb-1">Главный эксперт:</p>
        <v-select
          hide-details
          attach="#assessmentSessionForm div.form-group:nth-child(8n)"
          dense
          outlined
          v-model="editedSession.mainExpertId"
          item-text="fullName"
          item-value="id"
          :items="editedSession.expertsList || []"
          label="Выберите главного эксперта"
          chips
          required
          :rules="[val => !!Object.keys(val || {}).length || '']"
          validate-on-blur
          class="chip-wrap"
          :menu-props="{
            maxHeight: 175,
          }"
        >
          <template v-slot:selection="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.fullName }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </div>

      <div class="mb-3 form-group form-group_with-select">
        <p class="subtitle mb-1">Руководители:</p>
        <v-select
          ref="executive"
          attach="#assessmentSessionForm div.form-group:nth-child(9n)"
          hide-details
          dense
          outlined
          v-model="editedSession.executivesList"
          item-text="fullName"
          item-value="id"
          return-object
          :items="executives.content"
          label="Выбрать руководителей"
          multiple
          chips
          class="chip-wrap"
          @click="selectInfinityScroll('executive')"
          :menu-props="{
            maxHeight: 190,
          }"
        >
          <template v-slot:selection="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.fullName }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </div>

      <div class="mb-3 form-group form-group_with-select">
        <p class="subtitle mb-1">Наблюдатели:</p>
        <v-select
          ref="observer"
          attach="#assessmentSessionForm div.form-group:nth-child(10n)"
          hide-details
          dense
          outlined
          v-model="editedSession.observersList"
          item-text="fullName"
          item-value="id"
          return-object
          :items="observers.content"
          label="Выбрать наблюдателей"
          multiple
          chips
          class="chip-wrap"
          @click="selectInfinityScroll('observer')"
          :menu-props="{
            maxHeight: 190,
          }"
        >
          <template v-slot:selection="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.fullName }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </div>

      <div class="d-flex justify-space-between mt-12 pt-12 mb-4">
        <v-btn class="white--text primary flex-grow-1 mr-5" @click="cancel">
          Отменить
        </v-btn>
        <v-btn
          class="white--text primary flex-grow-1"
          @click="$refs.form.validate() && save(rows.length > 1 ? false : true)"
        >
          Сохранить
        </v-btn>
      </div>
    </v-form>
  </FormPagination>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import FormPagination from "@/components/shared/assets/table/FormPagination";
import { formPagination } from "@/use/form";
import usePagination from "@/use/pagination";

export default {
  props: {
    isEdit: Boolean,
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    session: {
      type: Object,
      default: () => {
        return {
          expertsList: [],
          executivesList: [],
          observersList: [],
        };
      },
    },
  },
  components: {
    FormPagination,
  },
  setup(_, { emit, root, refs }) {
    const store = root.$store;

    _.session.executivesList = _.session.leadersList || [];

    const { getSelectMenu, scrollHandler } = usePagination();

    const editedSession = ref(JSON.parse(JSON.stringify(_.session)));
    const initialSession = ref(JSON.parse(JSON.stringify(editedSession.value)));
    const updateData = val => {
      if (initialSession.value.startTimestamp) {
        let datetime = val.startTimestamp.split("T");
        val.date = datetime[0];
        val.time = datetime[1].substr(0, 5);
      }
      initialSession.value = val;
      editedSession.value = JSON.parse(JSON.stringify(val));

      const editedIndex = _.rows.findIndex(el => el.id == val.id);

      _.rows.splice(editedIndex, 1, val);

      if (refs.form) {
        refs.form.resetValidation();
      }
    };

    updateData(editedSession.value);

    const dateMenu = ref(false);
    const timeMenu = ref(false);
    const timeEndMenu = ref(false);
    const notifyDateMenu = ref(false);
    const notifyTimeMenu = ref(false);

    const { checkChanges, getNextRow, getPrevRow } = formPagination();

    const selectInfinityScroll = refName => {
      setTimeout(() => {
        const menu = getSelectMenu(refs[refName].$el);

        scrollHandler(menu, async () => {
          const userdata = store.getters.getShortUsers(refName);

          if (!userdata.last) {
            await store.dispatch("fetchUsers", {
              role: refName,
              short: true,
              noSession: refName === "participant",
            });
          }
        });
      }, 0);
    };

    const participants = computed(() => {
      const users = store.getters.getShortUsers("participant");

      if (initialSession.value.participantUser) {
        if (
          !users.content.find(
            el => el.id == initialSession.value.participantUser.id
          )
        ) {
          users.content.unshift({
            id: initialSession.value.participantUser.id,
            fullName: `${initialSession.value.participantUser.lastName} ${initialSession.value.participantUser.firstName} ${initialSession.value.participantUser.middleName}`,
          });
        }
      }

      return users;
    });
    const experts = computed(() => {
      const users = store.getters.getShortUsers("expert");
      const usersIds = users.content.map(u => u.id);

      if (initialSession.value.expertsList) {
        const lostUsers = initialSession.value.expertsList.filter(
          el => !usersIds.includes(el.id)
        );

        lostUsers.forEach(el => users.content.unshift(el));
      }

      return users;
    });
    const executives = computed(() => {
      const users = store.getters.getShortUsers("executive");
      const usersIds = users.content.map(u => u.id);

      if (_.session.leadersList) {
        const lostUsers = initialSession.value.leadersList.filter(
          el => !usersIds.includes(el.id)
        );

        lostUsers.forEach(el => users.content.unshift(el));
      }

      return users;
    });
    const observers = computed(() => {
      const users = store.getters.getShortUsers("observer");
      const usersIds = users.content.map(u => u.id);

      if (initialSession.value.observersList) {
        const lostUsers = initialSession.value.observersList.filter(
          el => !usersIds.includes(el.id)
        );

        lostUsers.forEach(el => users.content.unshift(el));
      }

      return users;
    });

    const cancel = () => {
      emit("close");
    };

    const save = (close = true) => {
      editedSession.value.leadersList = JSON.parse(
        JSON.stringify(editedSession.value.executivesList)
      );
      delete editedSession.value.executivesList;

      emit("save", editedSession.value);
      updateData(editedSession.value);
      if (close) {
        emit("close");
      }
    };

    const nextPage = saveChanges => {
      if (saveChanges) {
        save(false);
      }
      updateData(getPrevRow(initialSession.value, _.rows));
    };

    const prevPage = saveChanges => {
      if (saveChanges) {
        save(false);
      }

      updateData(getNextRow(initialSession.value, _.rows));
    };

    const closeAllMenus = () => {
      console.log(refs);
      refs.form.inputs.forEach(el => (el.$attrs["aria-expanded"] = "false"));
    };

    return {
      editedSession,
      initialSession,
      checkChanges,
      getNextRow,
      getPrevRow,
      selectInfinityScroll,
      participants,
      experts,
      executives,
      observers,
      dateMenu,
      timeMenu,
      timeEndMenu,
      notifyDateMenu,
      notifyTimeMenu,
      cancel,
      save,
      nextPage,
      prevPage,
      closeAllMenus,
    };
  },
};
</script>
<style lang="scss">
.assessment-form {
  .menuable__content__active {
    max-height: 135px;
  }
}

.form-group_with-select .v-menu__content {
  top: unset !important;
  margin-top: -44px;
  left: unset !important;
  position: absolute;
}
</style>
