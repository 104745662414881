<template>
  <v-container>
    <v-row
      justify="end"
      align="center"
      :style="{
        height: '68px',
      }"
    >
      <v-col class="d-flex justify-end">
        <div>
          <span class="filter-btn" @click="switchFilterToggle">
            {{ !filterToggle ? "Показать фильтры" : "Скрыть фильтры" }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-container>
        <DataFilter v-if="filterToggle" />
        <infinity-collection @scrolled="nextPage">
          <v-data-table
            :mobile-breakpoint="null"
            fixed-header
            :single-select="false"
            show-select
            class="elevation-5"
            :loading="loading"
            single-expand
            :headers="headers"
            :items="data.content"
            item-key="id"
            :items-per-page="data.content.length"
            hide-default-footer
            height="450px"
            v-model="selectedRow"
            @click:row="editRow"
            :custom-sort="customSort"
          >
          </v-data-table>
        </infinity-collection>

        <div class="d-flex justify-space-between mt-8">
          <div class="d-flex">
            <v-btn
              class="white--text primary mr-9"
              style="min-width: 220px"
              :disabled="!selectedRow.length"
              @click="switchToggle(true, 'edit-multiply', 600)"
            >
              Редактировать ({{ selectedRow.length }})
            </v-btn>
            <confirm
              text="Удалить выбранные записи?"
              :btnDisabled="!selectedRow.length"
              @confirm="removeSession(selectedRow)"
            >
              Удалить ({{ selectedRow.length }})
            </confirm>
            <v-btn
              class="white--text primary ml-9"
              style="min-width: 220px"
              @click="downloadExcel"
            >
              Скачать
              {{ selectedRow.length ? ` (${selectedRow.length})` : " все" }}
            </v-btn>
          </div>

          <v-btn
            class="white--text primary"
            style="min-width: 235px"
            @click="switchToggle(true, 'create', 600)"
          >
            Добавить оценочную сессию
          </v-btn>
        </div>
      </v-container>
    </v-row>

    <modal :dialog="toggle" :width="toggle.width" persistent>
      <AssessmentSessionForm
        v-if="toggle.view == 'create'"
        @close="switchToggle(false, 'create', 600)"
        @save="createSession"
      />
      <AssessmentSessionForm
        v-if="toggle.view == 'edit'"
        isEdit
        :session="editedRow"
        @close="switchToggle(false, 'edit', 600)"
        @save="updateSession"
      />
      <AssessmentSessionForm
        v-if="toggle.view == 'edit-multiply'"
        isEdit
        :session="selectedRow[0]"
        :rows="selectedRow"
        @close="switchToggle(false, 'edit', 600)"
        @save="updateSession"
      />
    </modal>
  </v-container>
</template>

<script>
import { ref, computed, inject, onMounted } from "@vue/composition-api";
import useTableHeaders from "@/use/tableHeaders";
import { useSetFilters, useModalToggle, useToggle } from "@/use/helpers";
import DataFilter from "@/components/shared/assets/table/DataFilter";
import AssessmentSessionForm from "./sessions/AssessmentSessionForm";
import { customSort } from "@/use/tableHeaders";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const { toggle: filterToggle, switchToggle: switchFilterToggle } =
      useToggle();
    const { toggle, switchToggle } = useModalToggle();

    const loading = inject("loading");

    const selectedRow = ref([]);

    const assessmentSessions = computed(
      () => store.getters.getAssessmentSessions.content
    );

    const { assessmentSessionHeaders } = useTableHeaders(store);
    const headers = computed(() => assessmentSessionHeaders);
    const data = computed(() => store.getters.getAssessmentSessions);

    const editedRow = ref({});

    const editRow = row => {
      editedRow.value = assessmentSessions.value.find(el => el.id == row.id);
      switchToggle(true, "edit", 600);
    };

    const createSession = data =>
      store.dispatch("createAssessmentSession", data);

    const updateSession = data =>
      store.dispatch("updateAssessmentSession", data);

    const removeSession = data =>
      store
        .dispatch(
          "removeAssessmentSessions",
          data.map(el => el.id)
        )
        .then(() => (selectedRow.value = []));

    const nextPage = async () => {
      loading.value = true;

      await store.dispatch("fetchAssessmentSessions");

      loading.value = false;
    };

    const downloadExcel = () =>
      store.dispatch("downloadExcel", {
        target: "assessment_sessions",
        ids: selectedRow.value.map(el => el.id),
      });

    onMounted(() => localStorage.setItem("lastTab", "AssessmentSessions"));

    return {
      filterToggle,
      switchFilterToggle,
      toggle,
      switchToggle,
      loading,
      assessmentSessions,
      selectedRow,
      editedRow,
      editRow,
      createSession,
      updateSession,
      removeSession,
      headers,
      data,
      ...useSetFilters(headers, assessmentSessions),
      nextPage,
      customSort,
      downloadExcel,
    };
  },
  components: {
    DataFilter,
    AssessmentSessionForm,
  },
};
</script>
